import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=1cbd167c&scoped=true&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=1cbd167c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cbd167c",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu,QSpace,QIcon});
