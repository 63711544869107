import { render, staticRenderFns } from "./DashboardFilterView.vue?vue&type=template&id=19e5b3f0&scoped=true&"
import script from "./DashboardFilterView.vue?vue&type=script&lang=js&"
export * from "./DashboardFilterView.vue?vue&type=script&lang=js&"
import style0 from "./DashboardFilterView.vue?vue&type=style&index=0&id=19e5b3f0&prod&lang=scss&scoped=true&"
import style1 from "./DashboardFilterView.vue?vue&type=style&index=1&id=19e5b3f0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e5b3f0",
  null
  
)

export default component.exports
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChatMessage,QChip,QMenu});
